<template>
	<div class="card card-custom card-stretch gutter-b">
		<div class="card-header border-0">
			<h3 class="card-title font-weight-bolder text-dark">{{ title }} - iDirect Details</h3>
		</div>
		<div class="card-body pt-0 d-flex justify-content-center">
			<ul v-if="data" class="details">
				<tr>
					<td>Software Version</td>
					<th class="data">{{ data.software_version }}</th>
				</tr>
				<tr>
					<td>BUC</td>
					<th class="data">
						{{ data.buc.name }} LO: {{ data.buc.freq }}
						<span v-if="data.buc.DC">{{ data.buc.DC }}</span>
					</th>
				</tr>
				<tr>
					<td>LNB</td>
					<th class="data">
						{{ data.lnb.name }} LO: {{ data.lnb.freq }}
						<span v-if="data.lnb.DC">{{ data.lnb.DC }}</span>
					</th>
				</tr>
				<tr>
					<td>Antenna</td>
					<th class="data">{{ data.antname }}</th>
				</tr>
				<tr v-if="data.huntfreq">
					<td>Antenna IP:</td>
					<th class="data">{{ data.antaddress }}:{{ data.antport }}</th>
				</tr>
				<tr v-if="azEl.azimuth">
					<td>Azimuth</td>
					<th class="data">{{ azEl.azimuth }}</th>
				</tr>
				<tr v-if="azEl.elevation">
					<td>Elevation</td>
					<th class="data">{{ azEl.elevation }}</th>
				</tr>
				<tr v-if="data.spacecraft && data.spacecraft.DownlinkCenterFreq">
					<td>RX Frequency</td>
					<th class="data">{{ data.spacecraft.DownlinkCenterFreq }} MHz</th>
				</tr>
				<tr v-if="data.spacecraft && data.spacecraft.DownlinkCenterFreq">
					<td>Band</td>
					<th class="data">{{ data.spacecraft.DownlinkCenterFreq > 10000 ? 'Ku' : 'C' }}</th>
				</tr>
				<tr>
					<td>Derived ID (DID)</td>
					<th class="data">{{ device.Source }}</th>
				</tr>
				<tr v-if="availability">
					<td>Availability:</td>
					<b-spinner v-if="!idirect || !idirect.availability" small></b-spinner>
					<th v-else class="data">
						{{ idirect.availability }}<span v-if="idirect.status === 'available'">%</span>
					</th>
				</tr>
			</ul>
		</div>
	</div>
</template>

<script>
import { getAzEl } from '@/helpers.js';

export default {
	name: 'IdirectDetails',
	props: {
		device: {
			type: Object,
			required: true,
		},
		widgetProps: {
			required: true,
			default: null,
		},
		updated: {
			type: Number,
		},
	},
	data() {
		return {
			idirect: null,
			title: this.widgetProps?.label || this.device.Name,
		};
	},
	computed: {
		data() {
			return this.device.stats.data;
		},
		availability() {
			return this.widgetProps?.availability;
		},
		azEl() {
			if (this.data.spacecraft?.Longitude) {
				return getAzEl(this.data.lat, this.data.lon, this.data.spacecraft.Longitude);
			}
			return false;
		},
	},
	methods: {
		load() {
			if (this.availability) {
				let params = {};
				if (this.widgetProps?.ipslasource) {
					params.ipsla = this.widgetProps.ipslasource.Source;
				}
				this.$http.get(`idirect/availability/${this.device.Source}`, params).then(resp => {
					this.idirect = resp.data.data;
				});
			}
		},
	},
	watch: {
		updated() {
			this.load();
		},
	},
	created() {
		this.load();
	},
};
</script>

<style>
.details {
	list-style: none;
	font-size: 1.15em;
	font-weight: 300;
}
.details td {
	width: 200px;
	text-align: right;
	padding-right: 10px;
}
.details th.data {
	font-weight: 500;
}
</style>
